import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql } from 'gatsby'
import ds from '../theme'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import IconeHostdime from '../images/logoHostdime.svg'
import IconeMap from '@material-ui/icons/Explore'
import IcoCollections from '@material-ui/icons/Collections'
import GuiaMarca from '../documentos/Guia_da_Marca_HostDime.pdf'
// import ImagesZip from '../documentos/images-hostdimebr.zip'
import { css } from '@emotion/core'

import { Container, mq } from '../components/styles/Layout.styles'
import {
  styledPressKit,
  headignContent
} from '../components/styles/PressKit.styles'
import { btnLink } from '../components/styles/OutlineButton.styles'

const PressKitItem = ({
  legend,
  icon: Icon,
  src,
  cta,
  linkTo,
}) => {
  return (
    <div
      style={{
        borderRadius: 16,
        backgroundColor: '#f3f5f5'
      }}
    >
      <Div
        style={{
          padding: '27px 0',
          fontSize: 11.8,
          fontWeight: 600,
          lineHeight: 1.36,
          letterSpacing: 2,
          textAlign: 'center',
          color: 'rgba(0, 0, 0, 0.87)',
          textTransform: 'uppercase'
        }}
      >
        {legend}
      </Div>
      <div
        style={{
          padding: '0 16px 40px 16px',
          backgroundColor: '#ffffff',
          boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.14)',
          borderRadius: 12,
          height: 216
        }}
        css={
          css(
            mq({
              '&:not(:last-child)': {
                marginBottom: ds.space(3)
              }
            })
          )
        }
      >
        <Div
          flexDirection='column'
          alignItems='center'
        >
          <Div
            alignItems='center'
            style={{
              width: 328,
              marginTop: 40,
              height: 42,
              justifyContent: 'center'
            }}
          >
            {Icon && <Icon
              style={{
                color: '#ff5800',
                width: 42,
                height: 42
              }}
            />}
            {src && <img
              src={src}
            />}
          </Div>
          <Div
            style={{
              width: 50,
              height: 1,
              borderBottom: 'solid 4px #f3f5f5',
              margin: '35px 0 49.5px 0'
            }}
          />
          <a
            href={linkTo}
            css={btnLink}
            style={{
              maxWidth: 210
            }}
          >
            {cta}
          </a>
        </Div>
      </div>
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledPressKit}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={headignContent}
        >
          <Column
            width={[1]}
          >
            <span>IMPRENSA</span>
            <H4>Press Kit e Guia da Marca</H4>
            <Paragraph>Em nosso Guia, você encontrará tudo o que precisa para usar a nossa marca de forma adequada: nossas diretrizes, arquivos prontos para aplicação, imagens e fontes.</Paragraph>
          </Column>
        </Row>
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'row']}
          alignItems={['center']}
          mt={[70, 50]}
          mb={[20, 70]}
        >
          <Column
            mb={[50]}
            order={[2, 2, 1, 1]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <PressKitItem
                legend='logo hostdime brasil'
                src={IconeHostdime}
                cta='baixar arquivos'
                linkTo='https://hostdime.com.br/site/material/HostDimeLogoFiles.zip'
              />
            </Div>
          </Column>
          <Column
            mb={[50]}
            order={[2, 2, 1, 1]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <PressKitItem
                legend='guia da marca'
                icon={IconeMap}
                cta='baixar o guia'
                linkTo={GuiaMarca}
              />
            </Div>
          </Column>
          <Column
            mb={[50]}
            order={[2, 2, 1, 1]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <PressKitItem
                legend='nossas fotos'
                icon={IcoCollections}
                cta='baixar fotos'
                linkTo='https://hostdime.com.br/site/material/images-hostdimebr.zip'
              />
            </Div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({ data }) => {
  return (
    <Layout>
      <Seo 
        title='Press Kit e Guia da Marca | HostDime Brasil'
        description='O nosso laranja é guia de mais criatividade e inovação no mercado de TI. Acesse nossas diretrizes para uso da marca oficial.'
      />
      <PageTemplate
        title='Press Kit e Guia da Marca'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
